import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { Field, withFormik } from "formik";
import { getInstitutionDataPerMonth, getInstitutionJournals, getInstitutionArticles, getArticlesPerInstitution, getArticleDetails } from "../../helpers";
import {
    PieChart,
    Table,
    Card,
    Loader,
    ErrorMessage,
    AsyncInput,
    MultiBarsChart,
    loadRorOptions,
    ArticleDetailsModal,
    NavigationTabs
} from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";
import { articlesPerInstitutionColumns, publishedArticlesColumns } from "./columnsData";
const Institutions = () => {
    const [loading, setLoading] = useState(false);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [error, setError] = useState(false);
    const [articlesPerInstitution, setArticlesPerInstitution] = useState([]);
    const [institutionJournals, setInstitutionJournals] = useState(null);
    const [dataPerMonth, setDataPerMonth] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isArticleOpen, setIsArticleOpen] = useState(false);
    const [publishedArticles, setPublishedArticles] = useState(null);
    useEffect(() => {
        setLoading(true);
        getArticlesPerInstitution()
            .then(res => {
                setArticlesPerInstitution(res.data);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, []);
    useEffect(() => {
        if (selectedInstitution) {
            setLoading(true);
            const fetchData = async () => {
                try {
                    const [
                        dataPerMonthResponse,
                        institutionJournalsResponse,
                        publishedArticlesResponse,
                    ] = await Promise.all([
                        getInstitutionDataPerMonth(encodeURIComponent(selectedInstitution)),
                        getInstitutionJournals(encodeURIComponent(selectedInstitution)),
                        getInstitutionArticles(encodeURIComponent(selectedInstitution), 'published')
                    ])
                    setDataPerMonth(dataPerMonthResponse.data);
                    setInstitutionJournals(institutionJournalsResponse.data);
                    setPublishedArticles(publishedArticlesResponse.data);
                } catch (error) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [selectedInstitution]);
    const handleSelectArticle = row => {
        const articleId = row && row.original ? row.original.id : null;
        setSelectedArticle(articleId);
    };
    const handleSelectInstitution = row => {
        const rorId = row && row.original ? row.original["ror"] : null;
        setSelectedInstitution(rorId);
    };
    useEffect(() => {
        if (selectedArticle) {
            getArticleDetails(selectedArticle)
                .then(res => setArticleDetails(res.data))
                .then(setIsArticleOpen(true))
                .then(setSelectedArticle(null))
        }
    }, [selectedArticle]);
    const tabs = [
        {
            id: '1',
            title: 'Published',
            content: (
                <div>
                    {publishedArticles && (
                        <Table
                            data={publishedArticles}
                            columns={publishedArticlesColumns}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </div>
            ),
        },
    ];
    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <div className="d-flex justify-content-end mb-4">
                    <Field
                        component={AsyncInput}
                        loadOptions={loadRorOptions}
                        setValue={setSelectedInstitution}
                        placeholder="Select institution"
                    />
                </div>
                {!selectedInstitution && !loading && !error && (
                    <div className="my-5">
                        <Table
                            title="Articles per institution"
                            data={articlesPerInstitution}
                            columns={articlesPerInstitutionColumns}
                            onSelectRow={handleSelectInstitution}
                        />
                    </div>
                )}
                {loading && <Loader />}
                {!loading && error && <ErrorMessage />}
                {!loading && selectedInstitution && (
                    <>
                        <MDBRow className="mt-4 d-flex justify-content-center">
                            <MDBCol md="8" className="mb-5 mt-4">
                                {dataPerMonth && <Card
                                    title="Articles/messages per month:"
                                    content={
                                        <MultiBarsChart
                                            data={dataPerMonth}
                                            xAxisKey="month"
                                            yAxisKey={["articles", "messages"]}
                                            yAxisLabel="count"
                                            labels={["Articles", "Messages"]}
                                        />
                                    }
                                />}
                            </MDBCol>
                            <MDBCol md="4" className="mb-5 mt-4">
                                {institutionJournals && <Card
                                    title="Per journal:"
                                    content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                                />}
                            </MDBCol>
                        </MDBRow>
                        <NavigationTabs tabs={tabs} />
                    </>
                )}
                <ArticleDetailsModal
                    isOpen={isArticleOpen}
                    setIsOpen={setIsArticleOpen}
                    articleDetails={articleDetails}
                    tabItems={userSettings.details}
                />
            </div>
        </div>
    );
};
export default withFormik({})(Institutions);