import { publisher_id } from "../constants";
import { axiosInstance, axiosOasbInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getArticles(searchValue, offset, perPage, sortBy, sortDir, selection, filterValue = "", ror = "") {
    return await axiosInstance
        .get(`pnas/tracker/articles?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&selection=${selection}&issn=${filterValue}&ror=${ror}
        `)
        .catch(handleErrors)
};

export async function getArticleDetails(selectedMessage) {
    return await axiosInstance
        .get(`pnas/tracker/article/${selectedMessage}`)
        .catch(handleErrors)
};

export async function getJournals() {
    return await axiosOasbInstance
        .get(`/journal/publisher/${publisher_id}`)
        .catch(handleErrors)
};
